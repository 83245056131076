var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('screen',{staticClass:"steps-view",staticStyle:{"background":"transparent","box-shadow":"none","position":"relative"},attrs:{"size":"large"}},[_c('div',{style:({ position: 'absolute', top: 0, right: 0 })},[_c('ui-button',{on:{"click":_vm.openInviteModal}},[_vm._v("Invite a co-worker")])],1),_c('card',{staticClass:"body",style:({ background: '#1EADFF', padding: '50px', margin: 'auto' }),attrs:{"size":"large","type":"black"}},[_c('div',{attrs:{"slot":"title"},slot:"title"},[_c('h1',[_vm._v("Provide a Donation Source")])]),_c('div',{style:({ color: 'white', fontSize: '18px' }),attrs:{"slot":"subtitle"},slot:"subtitle"},[_vm._v(" We need access to your bank account to send your RoundUps to "+_vm._s(_vm.institution ? _vm.institution.name : 'your chairty')+". ")]),_c('div',{attrs:{"slot":"body"},slot:"body"},[_c('img',{attrs:{"src":require("@/assets/Plaid_Visa_logo.png")}}),_c('p',{style:({ margin: '20px 0' })},[_vm._v(" We use Plaid, a Visa company, so we can automatically authenticate to your bank and instantly authorize withdrawals to your charity. ")]),_c('div',{staticClass:"donationSourceWrapper"},[_c('donation-source-option',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Log in to your bank")]),_c('div',{attrs:{"slot":"subtitle"},slot:"subtitle"},[_vm._v("Instant")]),(_vm.linkToken)?_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('plaid-link',_vm._b({attrs:{"coountryCode":"US"}},'plaid-link',{
								env: _vm.env,
								webhook: _vm.webhook,
								clientName: _vm.clientName,
								countryCodes: _vm.countryCodes,
								onSuccess: _vm.onSuccess,
								isWebView: true,
								product: _vm.product,
								token: _vm.linkToken
							},false),[_c('ui-button',[_vm._v(" Sign In ")])],1)],1):_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('ui-button',{attrs:{"disabled":"true","loading":"true"}},[_vm._v(" Sign In ")])],1)]),_c('div',{staticClass:"donationSourceWrapper-or"},[_vm._v("OR")]),_c('donation-source-option',[_c('div',{attrs:{"slot":"title"},slot:"title"},[_vm._v("Link with account numbers")]),_c('div',{attrs:{"slot":"subtitle"},slot:"subtitle"},[_vm._v("2-3 business days")]),_c('div',{attrs:{"slot":"action"},slot:"action"},[_c('a',{attrs:{"href":"https://help.roundupapp.com/how-do-i-link-my-donation-source-with-an-account-number","target":"_blank"}},[_c('ui-button',[_vm._v(" Instructions ")])],1)])])],1),_c('div',{staticClass:"security"},[_c('p',{style:({ marginBottom: 0, marginTop: 0 })},[_vm._v(" This process is completely automated so your privacy is protected. ")])])])]),_c('modal',{staticClass:"plaid-connect-bank-modal",model:{value:(_vm.showSelectAccount),callback:function ($$v) {_vm.showSelectAccount=$$v},expression:"showSelectAccount"}},[_c('h1',{staticStyle:{"font-size":"1.5rem !important"}},[_vm._v(" Select Your Donation Funding Account ")]),_c('div',{staticStyle:{"width":"100%"}},[_c('el-table',{style:({ width: '100%', overflowX: 'scroll' }),attrs:{"slot":"table","data":_vm.metadata.accounts.filter(
						function (acc) { return acc.type === 'depository'; }
					)},on:{"row-click":_vm.linkPlaid},slot:"table"},[_c('el-table-column',{attrs:{"prop":"name","label":"Name"}}),_c('el-table-column',{attrs:{"prop":"mask","label":"Mask"}})],1)],1)]),_c('modal',{model:{value:(_vm.showNoAccounts),callback:function ($$v) {_vm.showNoAccounts=$$v},expression:"showNoAccounts"}},[_c('h1',{staticStyle:{"font-size":"1.5rem !important"}},[_vm._v(" None of those accounts can be used as a donation source. Please log into the bank with a checking account. ")])]),_c('modal',{model:{value:(_vm.loading),callback:function ($$v) {_vm.loading=$$v},expression:"loading"}},[_c('div',{staticStyle:{"width":"100%","height":"150px","textalign":"center"}},[_c('h1',{staticStyle:{"font-size":"1.5rem !important"}},[_vm._v(" Setting Up Bank Connection... ")]),_c('div',{staticStyle:{"width":"60%","position":"relative","height":"100px"}},[_c('loader',{attrs:{"loading":_vm.loading,"size":"big"}})],1)])]),_c('modal',{model:{value:(_vm.inviteModal),callback:function ($$v) {_vm.inviteModal=$$v},expression:"inviteModal"}},[_c('user-edit-view')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }