<template>
	<screen
		class="steps-view"
		size="large"
		style="background: transparent; box-shadow: none; position: relative"
	>
		<div :style="{ position: 'absolute', top: 0, right: 0 }">
			<ui-button @click="openInviteModal">Invite a co-worker</ui-button>
		</div>
		<card
			class="body"
			size="large"
			:style="{ background: '#1EADFF', padding: '50px', margin: 'auto' }"
			type="black"
		>
			<div slot="title">
				<h1>Provide a Donation Source</h1>
			</div>
			<div slot="subtitle" :style="{ color: 'white', fontSize: '18px' }">
				We need access to your bank account to send your RoundUps to
				{{ institution ? institution.name : 'your chairty' }}.
			</div>
			<div slot="body">
				<img src="@/assets/Plaid_Visa_logo.png" />
				<p :style="{ margin: '20px 0' }">
					We use Plaid, a Visa company, so we can automatically
					authenticate to your bank and instantly authorize
					withdrawals to your charity.
				</p>

				<div class="donationSourceWrapper">
					<donation-source-option>
						<div slot="title">Log in to your bank</div>
						<div slot="subtitle">Instant</div>
						<div slot="action" v-if="linkToken">
							<plaid-link
								v-bind="{
									env,
									webhook,
									clientName,
									countryCodes,
									onSuccess,
									isWebView: true,
									product,
									token: linkToken
								}"
								coountryCode="US"
							>
								<ui-button> Sign In </ui-button>
							</plaid-link>
						</div>
						<div slot="action" v-else>
							<ui-button disabled="true" loading="true">
								Sign In
							</ui-button>
						</div>
					</donation-source-option>
					<div class="donationSourceWrapper-or">OR</div>
					<donation-source-option>
						<div slot="title">Link with account numbers</div>
						<div slot="subtitle">2-3 business days</div>
						<div slot="action">
							<a
								href="https://help.roundupapp.com/how-do-i-link-my-donation-source-with-an-account-number"
								target="_blank"
							>
								<ui-button> Instructions </ui-button>
							</a>
						</div>
					</donation-source-option>
				</div>

				<div class="security">
					<p v-bind:style="{ marginBottom: 0, marginTop: 0 }">
						This process is completely automated so your privacy is
						protected.
					</p>
				</div>
			</div>
		</card>
		<modal v-model="showSelectAccount" class="plaid-connect-bank-modal">
			<h1 style="font-size: 1.5rem !important">
				Select Your Donation Funding Account
			</h1>
			<div style="width: 100%">
				<el-table
					slot="table"
					@row-click="linkPlaid"
					:data="
						metadata.accounts.filter(
							acc => acc.type === 'depository'
						)
					"
					:style="{ width: '100%', overflowX: 'scroll' }"
				>
					<el-table-column prop="name" label="Name">
					</el-table-column>
					<el-table-column prop="mask" label="Mask">
					</el-table-column>
				</el-table>
			</div>
		</modal>
		<modal v-model="showNoAccounts">
			<h1 style="font-size: 1.5rem !important">
				None of those accounts can be used as a donation source. Please
				log into the bank with a checking account.
			</h1>
		</modal>
		<modal v-model="loading">
			<div style="width: 100%; height: 150px; textalign: center">
				<h1 style="font-size: 1.5rem !important">
					Setting Up Bank Connection...
				</h1>
				<div style="width: 60%; position: relative; height: 100px">
					<loader :loading="loading" size="big" />
				</div>
			</div>
		</modal>
		<modal v-model="inviteModal">
			<user-edit-view></user-edit-view>
		</modal>
	</screen>
</template>

<script>
import Datepicker from 'vuejs-datepicker'
import { mapState, mapActions } from 'vuex'
import table from '@/mixins/table'
import Screen from '../../ui/Screen'
import UiInput from '../../ui/Input'
import UiLabel from '../../ui/Label'
import PlaidLink from 'vue-plaid-link'
import Modal from '../../ui/Modal'
import Loader from '../../ui/Loader'
import Card from '../../ui/Card'
import UiButton from '../../ui/Button'
import UserEditView from '../Users/UserEditView'
import DonationSourceOption from '../../ui/donation-source/OptionCard'

export default {
	name: 'steps-view',
	components: {
		UserEditView,
		Screen,
		UiButton,
		UiLabel,
		Datepicker,
		Card,
		UiInput,
		Loader,
		PlaidLink,
		Modal,
		DonationSourceOption
	},
	mixins: [table],
	data() {
		return {
			env: process.env.VUE_APP_plaidEnv,
			webhook: process.env.VUE_APP_plaidWebhook,
			clientName: 'RoundUp App',
			countryCodes: ['US'],
			product: 'auth',
			link: null,
			loading: false,
			showSelectAccount: false,
			metadata: { accounts: [] },
			plaid_token: '',
			inviteModal: false,
			showNoAccounts: false,
			linkToken: null
		}
	},
	computed: {
		...mapState({
			institution: ({ institutionDetail }) => institutionDetail.item,
			bankLoading: ({ merchant }) => merchant.isFetching,
			merchant: ({ merchant }) => merchant.item
		})
	},
	methods: {
		...mapActions(['getMyInstitution', 'addPlaid', 'fetchLinkToken']),
		openInviteModal() {
			this.inviteModal = true
		},
		skipStep() {
			window.analytics.track('roundup_skiped')
			localStorage.setItem('roundup_skip', true)
			this.navigate({ name: 'home' }, 'up')
		},
		onSuccess(token, metadata) {
			this.plaid_token = token
			this.metadata.accounts = metadata.accounts.filter(
				acc =>
					acc.type === 'depository' &&
					(acc.subtype === 'checking' || acc.subtype === 'savings')
			)

			if (this.metadata.accounts.length > 0) {
				if (this.metadata.accounts.length === 1) {
					const acc = this.metadata.accounts[0]
					switch (acc.verification_status) {
						case 'pending_manual_verification':
							this.addPlaid({
								token: this.plaid_token,
								account_id: acc.id,
								verification_status: acc.verification_status
							})
								.then(() => {
									this.$swal({
										title: 'Pending Account Verification',
										text: `We are making two small deposits. Please come back and enter those two amounts after they have been deposited. You can start to offer RoundUp, but we need to confirm this information before the end of the month.`,
										showCancelButton: false,
										allowOutsideClick: false,
										allowEscapeKey: false
									}).then(() => {
										if (
											sessionStorage.getItem(
												'bc_ru_token'
											)
										) {
											this.navigate({
												name: 'integrations.bigcommerce'
											})
										} else {
											this.navigate({
												name: 'step-verify-deposits'
											})
										}
									})
								})
								.catch(this.linkFailed)
							break
						default:
							this.addPlaid({
								token: this.plaid_token,
								account_id: acc.id,
								verification_status: acc.verification_status
							})
								.then(this.linkSuccessful)
								.catch(this.linkFailed)
					}
				} else {
					this.showSelectAccount = true
				}
			} else {
				this.showNoAccounts = true
			}
		},
		linkPlaid(row) {
			this.loading = true
			this.showSelectAccount = false
			this.addPlaid({
				token: this.plaid_token,
				account_id: row.id,
				verification_status: row.verification_status
			})
				.then(this.linkSuccessful)
				.catch(this.linkFailed)
		},
		linkSuccessful() {
			this.loading = false

			if (sessionStorage.getItem('bc_ru_token')) {
				this.navigate({ name: 'integrations.bigcommerce' })
			} else {
				this.navigate({ name: 'api', query: { onboarding: true } })
			}
		},
		linkFailed(error) {
			this.loading = false
			if (error.message) {
				this.$swal({
					title: 'Error',
					text: error.message,
					showCancelButton: true,
					cancelButtonText: 'View Accounts Again',
					allowOutsideClick: false,
					allowEscapeKey: false
				}).then(result => {
					if (!result.value) {
						this.showSelectAccount = true
					}
				})
			} else {
				this.$swal(
					'Error',
					'Unable to link accounts. Please try again.'
				)
			}
		}
	},
	mounted() {
		if (!this.merchant.payment_verified) {
			this.$router.push({ name: 'step-verify-deposits' })
			return
		}
		this.getMyInstitution()
		this.fetchLinkToken()
			.then(resp => {
				this.linkToken = resp.link_token
			})
			.catch(error => this.$swal('Error', error.message))
	}
}
</script>

<style lang="scss">
@import '@roundupapp/component-library/src/styles/index';
.donationSourceWrapper {
	display: grid;
	grid-template-columns: 1fr auto 1fr;
	gap: 20px;
	margin-bottom: 20px;
}
.donationSourceWrapper-or {
	padding-top: 20px;
	color: $white;
	opacity: 0.7;
}
.card {
	&.body {
		.card-header {
			padding-left: 20px;
			padding-right: 20px;
			margin-bottom: 10px;
			border-bottom: 0;
			.card-title {
				h1 {
					font-family: 'Bitter', serif;
					font-weight: normal;
					color: $white;
					margin: 0 0 20px 0;
				}
			}
		}
		.card-body {
			padding: 0;
		}
	}
}
.body {
	background: $roundup-cerulean;
	align-items: flex-start;
	display: flex;
	flex-direction: column;
	width: 50%;
	max-width: 600px;
	margin: auto;
	text-align: left;
	.plaid-link-button {
		background: transparent;
		color: $roundup-navy;
	}
	p {
		color: $white;
	}
	.link {
		cursor: pointer;
		text-align: center;
		margin-bottom: 0;
		margin-top: 0;
		&:hover {
			text-decoration: underline;
		}
	}
	.security {
		font-size: 12px;
		color: $white;
		opacity: 0.7;
	}
	img {
		height: 32px;
		width: auto;
	}
}
.plaid-link-button {
	background-color: transparent;
	border: transparent;
}

.plaid-connect-bank-modal {
	.el-table {
		.el-table__header-wrapper {
			th {
				.cell {
					font-weight: 600;
				}
			}
		}
	}
}
</style>
